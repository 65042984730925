import React, { FC, ChangeEvent, useState, useEffect, useRef } from 'react';
import { format } from 'date-fns';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import {
  Tooltip,
  Divider,
  Box,
  FormControl,
  InputLabel,
  Card,
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Select,
  MenuItem,
  Typography,
  useTheme,
  CardHeader,
  Modal,
  DialogContent,
  Input,
  TextField,
  Button
} from '@mui/material';

import Label from 'src/components/Label';
import { CryptoOrder, CryptoOrderStatus } from 'src/models/crypto_order';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import BulkActions from './BulkActions';
import { useModalStore } from '../../../zustandStore/store.attributes';
import { HttpModal } from '../../../components/HttpModal/HttpModal';
import CloseIcon from '@mui/icons-material/Close';
import { API } from '../../../config/config';
import {
  ActionCell,
  DynamicTable,
  TableSearch
} from '../../../components/DynamicTable/DynamicTable';
import { useTokenStore } from '../../../zustandStore/store.token';
import { PeonyKid } from '../../../interfaces/interfaces';
import { PeonyModalActivities } from '../../../components/PeonyModal/PeonyModal';
import {
  Child,
  PendingReports,
  Report
} from '../../../interfaces/PendingReports';
import { descendingComparator } from '../../../Utils';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

interface RecentOrdersTableProps {
  className?: string;
  cryptoOrders: CryptoOrder[];
}

interface Filters {
  status?: CryptoOrderStatus;
}

const getStatusLabel = (cryptoOrderStatus: CryptoOrderStatus): JSX.Element => {
  const map = {
    failed: {
      text: 'Failed',
      color: 'error'
    },
    completed: {
      text: 'Completed',
      color: 'success'
    },
    pending: {
      text: 'Pending',
      color: 'warning'
    }
  };

  const { text, color }: any = map[cryptoOrderStatus];

  return <Label color={color}>{text}</Label>;
};

const applyFilters = (
  cryptoOrders: CryptoOrder[],
  filters: Filters
): CryptoOrder[] => {
  return cryptoOrders.filter((cryptoOrder) => {
    let matches = true;

    if (filters.status && cryptoOrder.status !== filters.status) {
      matches = false;
    }

    return matches;
  });
};

const applyPagination = (
  cryptoOrders: CryptoOrder[],
  page: number,
  limit: number
): CryptoOrder[] => {
  return cryptoOrders.slice(page * limit, page * limit + limit);
};

const RecentOrdersTable: FC<RecentOrdersTableProps> = ({ cryptoOrders }) => {
  const [modalName, setModalName] = useState('');
  const state = useModalStore((state) => state);
  const action = state.actions;
  const dynamicTable = useRef<any>();
  const [retry, setRetry] = useState(false);
  const [searchData, setSearchData] = useState(null);

  const [selectedCryptoOrders, setSelectedCryptoOrders] = useState<string[]>(
    []
  );
  const selectedBulkActions = selectedCryptoOrders.length > 0;
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(5);
  const [report, setReport] = useState<Report[]>([]);
  const [filters, setFilters] = useState<Filters>({
    status: null
  });
  const [reportData, setReportData] = useState<Report>(null);

  const token = useTokenStore((state) => state.token);
  const [reportDialog, setReportDialog] = useState(false);
  const [sortBy, SetSortBy] = useState<string>('id');
  const [sortAsc, setSortAsc] = useState<'asc' | 'desc'>('asc');
  const [currentDate, setCurrentDate] = useState<dayjs.Dayjs | null>(
    dayjs(new Date())
  );
  const [tempData, setTempData] = useState<Report[]>([]);
  useEffect(() => {
    if (!reportDialog) {
      setReportData(null);
    }
  }, [reportDialog]);

  useEffect(() => {
    setReport(tempData.filter((report) => filterDate(tempData, report)));
  }, [currentDate]);

  const filterDate = (data: Report[], report: Report): boolean => {
    const dateOfReport = dayjs(report.date);
    const today = dayjs(currentDate);
    return dateOfReport.isSame(today, 'date');
  };

  useEffect(() => {
    fetch(`${API.HOST}${API.ENDPOINT.PENDING_REPORTS}`, {
      // 1. Change the URL
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token.token}`
      }
    }).then((response) => {
      response.json().then((data: PendingReports) => {
        // Set the previous data
        setTempData(data.data);
        // Filter the date to today
        const filterResult = data.data.filter((report) =>
          filterDate(data.data, report)
        );
        setReport(filterResult || []);
      });
    });
  }, [retry]);

  useEffect(() => {
    const _reports = report.sort((a, b) => {
      if (sortAsc === 'asc') {
        return descendingComparator(a, b, sortBy);
      }
      if (sortAsc === 'desc') {
        return -descendingComparator(a, b, sortBy);
      }
    });
    setReport(_reports);
  }, [sortBy, sortAsc]);

  const statusOptions = [
    {
      id: 'all',
      name: 'All'
    },
    {
      id: 'firstName',
      name: 'First Name'
    },
    {
      id: 'lastName',
      name: 'Last Name'
    },
    {
      id: 'gender',
      name: 'Gender'
    },
    {
      id: 'date',
      name: 'Report Date'
    }
  ];
  const showReport = (row: Report) => {
    setReportData(row);
    setReportDialog(true);
  };

  const handleStatusChange = (e: ChangeEvent<HTMLInputElement>): void => {
    let value = null;

    if (e.target.value !== 'all') {
      value = e.target.value;
    }

    setFilters((prevFilters) => ({
      ...prevFilters,
      status: value
    }));
  };

  const handleSelectAllCryptoOrders = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    setSelectedCryptoOrders(
      event.target.checked
        ? cryptoOrders.map((cryptoOrder) => cryptoOrder.id)
        : []
    );
  };

  const handleSelectOneCryptoOrder = (
    event: ChangeEvent<HTMLInputElement>,
    cryptoOrderId: string
  ): void => {
    if (!selectedCryptoOrders.includes(cryptoOrderId)) {
      setSelectedCryptoOrders((prevSelected) => [
        ...prevSelected,
        cryptoOrderId
      ]);
    } else {
      setSelectedCryptoOrders((prevSelected) =>
        prevSelected.filter((id) => id !== cryptoOrderId)
      );
    }
  };

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const filteredCryptoOrders = applyFilters(cryptoOrders, filters);
  const paginatedCryptoOrders = applyPagination(
    filteredCryptoOrders,
    page,
    limit
  );
  const selectedSomeCryptoOrders =
    selectedCryptoOrders.length > 0 &&
    selectedCryptoOrders.length < cryptoOrders.length;
  const selectedAllCryptoOrders =
    selectedCryptoOrders.length === cryptoOrders.length;
  const theme = useTheme();

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #d8d8d8',
    boxShadow: 24,
    borderRadius: '5px',
    p: 4
  };

  return (
    <Card>
      <HttpModal
        onClose={() => {
          action.setModal({ modalOpen: false });
        }}
        onSubmitCallback={(response) => {
          if (response.status > 200 && response.status < 300) {
            action.setModal({ modalOpen: false });
          }

          if (response.status > 400) {
            console.log('Error');
          }
        }}
        openModal={state.settings.modalOpen}
        fields={[
          {
            type: 'text',
            label: 'Attribute Name',
            placeholder: 'Attribute Name',
            name: 'attributeName',
            required: true,
            disabled: false,
            value: modalName,
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
              setModalName(e.target.value);
            }
          }
        ]}
        baseUrl={`${API.HOST}api/attributes`}
        method={'POST'}
        headers={{
          'Content-Type': 'application/json',
          Authorization: `Bearer ${API.TOKEN}`
        }}
        title={'Attribute'}
      />
      {selectedBulkActions && (
        <Box flex={1} p={2}>
          <BulkActions />
        </Box>
      )}
      {!selectedBulkActions && (
        <CardHeader
          action={
            <Box width={300}>
              <FormControl fullWidth variant="outlined">
                <FormControl fullWidth variant="outlined">
                  <DatePicker
                    onChange={(date) => {
                      console.log('Date changed....');
                      setCurrentDate(date);
                    }}
                    value={currentDate}
                    label="Pick a Report Date"
                  />
                </FormControl>
              </FormControl>
            </Box>
          }
          title="Kids"
        />
      )}
      <Divider />
      {reportData && (
        <PeonyModalActivities
          reportData={reportData}
          name={`${reportData.child.firstName} ${reportData.child.lastName}`}
          open={reportDialog}
          onClose={() => setReportDialog(false)}
          onRefresh={() => setRetry(!retry)}
        />
      )}

      <TableContainer>
        <DynamicTable
          sortByField={sortBy}
          onSort={(headerProperty) => {
            SetSortBy(headerProperty);
            setSortAsc(sortAsc === 'asc' ? 'desc' : 'asc');
          }}
          sort={sortAsc}
          searchElement={
            <TableSearch
              placeholder={'Search Attributes'}
              searchData={report}
              setSearchData={setSearchData}
            />
          }
          headers={[
            {
              key: 'firstName',
              displayName: 'First Name'
            },
            {
              key: 'lastName',
              displayName: 'Last Name'
            },
            {
              key: 'date',
              displayName: 'Report Date'
            }
          ]}
        >
          {(searchData || report).map((row: Report, index: number) => {
            return (
              <TableRow key={'tr' + index}>
                <TableCell key={row.child.firstName + index}>
                  {row.child.firstName}
                </TableCell>
                <TableCell key={row.child.lastName + index}>
                  {row.child.lastName}
                </TableCell>
                <TableCell key={row.date + index}>{row.date}</TableCell>

                <ActionCell
                  hideDelete={true}
                  hideEdit={true}
                  deleteEndpoint={`${API.HOST}api/attributes/${row.id}`}
                  payload={{ data: row }}
                  callback={() => setRetry(!retry)}
                >
                  <Tooltip title="Pending Report" arrow>
                    <Button
                      variant={'contained'}
                      onClick={async (e) => showReport(row)}
                      sx={{
                        color: theme.palette.primary.main
                      }}
                      color="inherit"
                      size="small"
                    >
                      View Report
                    </Button>
                  </Tooltip>
                </ActionCell>
              </TableRow>
            );
          })}
        </DynamicTable>
      </TableContainer>
      <Box p={2}>
        <TablePagination
          component="div"
          count={filteredCryptoOrders.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25, 30]}
        />
      </Box>
    </Card>
  );
};

RecentOrdersTable.propTypes = {
  className: PropTypes.string,
  cryptoOrders: PropTypes.array.isRequired
};
export default RecentOrdersTable;
