import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tab,
  TableCell,
  Tabs,
  Typography,
  TableRow,
  TableHead,
  Table,
  TableBody,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import './report-ui.css';
import { API } from '../../config/config';
import _ from 'lodash';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DeleteIcon from '@mui/icons-material/Delete';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { SaveAsTwoTone } from '@mui/icons-material';
import { TimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { useTokenStore } from '../../zustandStore/store.token';
import { Report } from '../../interfaces/PendingReports';

interface PeonyModalProps {
  open: boolean;
  onClose: () => void;
  reportData: Report;
  name: string;
  hideApproveButton?: boolean;
  hideEditButton?: boolean;
  label?: string;
  onRefresh?: () => void;
}

export const PeonyModalActivities = (props: PeonyModalProps) => {
  const [updateMessage, setUpdateMessage] = useState<string>('');
  const {
    open,
    onClose,
    reportData,
    name,
    hideApproveButton = false,
    hideEditButton = false,
    label,
    onRefresh
  } = props;

  const token = useTokenStore((state) => state.token);
  const [id, setId] = useState<string | null>(null);
  const [reports, setReports] = useState<any[]>([]);
  const [mappedReports, setMappedReports] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [mood, setMood] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [editButton, setEditButton] = useState<boolean>(false);
  const [sendXHR, setSendXHR] = useState<boolean>(false);
  const [dirty, setDirty] = useState<boolean>(false);
  useEffect(() => {
    (async () => {
      setId(reportData.id);
      setMood(reportData.mood);
      setMessage(reportData.message);
    })();
  }, []);

  const updateData = async() => {
    if (!id) throw new Error('Report ID is required');
    const response = await fetch(`${API.HOST}report/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token.token}`
      },
      body: JSON.stringify({
        mood,
        message
      })
    });
    const data = await response.json();
    console.log(data);
    setSendXHR(false);
    setDirty(false);
    setUpdateMessage('Comment or Mood has been updated');
    setEditButton(false);
    onRefresh();
  }



  useEffect(() => {
    // Clear the message once it has been updated
    if (updateMessage === null) return;
    setTimeout(() => {
      setUpdateMessage(null);
    }, 7000);
  }, [updateMessage]);

  const Approve = async () => {

    setLoading(true);
    try {
      const response = await fetch(`${API.HOST}report/${id}/validate-report`, {
        method: 'PUT',
        body: JSON.stringify({
          status: 'APPROVED'
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (response.status === 401) {
        alert('Operation failed: You are not authorized to make this call');
      }
      setLoading(false);
      if (onClose) {
        onClose();
      }
    } catch (e) {}
  };

  const processReport = (reports: []) => {
    const _reportModified = reports.map((reportObject: any) => {
      const report = reportObject.reportData;
      const reportType = reportObject.reportType;
      const _reportType = reportType.toLowerCase();
      // If it has a comment,wakeupTime, and time then it's nap report
      if (reportType === 'Nap') {
        return {
          id: report.id,
          type: _reportType,
          localDateTime: report.napTime,
          comment: report.comment,
          wakeUpDateTime: report.wakeUpTime,
          headerRows: [
            {
              key: 'wakeUpDateTime',
              label: 'WakeUp Time',
              type: 'datetime_local'
            },
            {
              key: 'localDateTime',
              label: 'Time',
              type: 'datetime_local'
            },
            {
              key: 'comment',
              label: 'Comment',
              type: 'text'
            }
          ],
          order: 2
        };
      }
      if (reportType === 'Snack') {
        return {
          type: _reportType,
          id: report.id,
          localDateTime: report.snackTime,
          snack: report.snack,
          headerRows: [
            {
              key: 'localDateTime',
              label: 'Time',
              type: 'datetime_local'
            },
            {
              key: 'snack',
              label: 'Snack',
              type: 'text',
              data_type: 'array'
            }
          ],
          order: 0
        };
      }
      if (reportType === 'Meal') {
        return {
          type: _reportType,
          id: report.id,
          mealName: report.mealName,
          localDateTime: report.mealTime,
          quantity: report.quantity,
          headerRows: [
            {
              key: 'localDateTime',
              label: 'Time',
              type: 'datetime_local'
            },
            {
              key: 'mealName',
              label: 'Meal Name',
              type: 'text'
            },

            {
              key: 'quantity',
              label: 'Quantity',
              type: 'meal_quantity'
            }
          ],
          order: 0
        };
      }

      if (reportType === 'PhysicalActivities') {
        return {
          type: 'physical-activities',
          id: report.id,
          activity: report.activity,
          localDateTime: report.physicalActivityTime,
          headerRows: [
            {
              key: 'localDateTime',
              label: 'Time',
              type: 'datetime_local'
            },
            {
              key: 'activity',
              label: 'Activities',
              type: 'text'
            }
          ],
          order: 0
        };
      }
      if (reportType === 'Diaper') {
        return {
          type: _reportType,
          id: report.id,
          diaperCondition: report.diaperCondition,
          localDateTime: report.diaperTime,
          potty: report.potty,
          headerRows: [
            {
              key: 'localDateTime',
              label: 'Time',
              type: 'datetime_local'
            },
            {
              key: 'diaperCondition',
              label: 'Diaper Condition',
              type: 'diaperCondition'
            },

            {
              key: 'potty',
              label: 'Potty',
              type: 'potty'
            }
          ],

          order: 1
        };
      }

      if (reportType === 'Story') {
        return {
          type: _reportType,
          id: report.id,
          storyName: report.storyName,
          localDateTime: report.storyTime,
          headerRows: [
            {
              key: 'storyTime',
              label: 'Time',
              type: 'datetime_local'
            },
            {
              key: 'storyName',
              label: 'Story Name',
              type: 'text'
            }
          ],
          order: 0
        };
      }
    });
    setMappedReports(_reportModified);

  };

  useEffect(() => {
    (async () => {
      if (!id) return;
      const response = await fetch(`${API.HOST}report/${id}/all-reports`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      const data = await response.json();
      setReports(data);
      processReport(data);
    })();
  }, [id]);

  const [value, setValue] = React.useState(0);

  const MenuTabs = [
    'Snack Report',
    'Meal Report',
    'Nap Report',
    'Diaper Report',
    'Arts and Craft Report',
    'Physical Activity Report',
    'All Reports'
  ];

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  // @ts-ignore
  return (
    <>
      <Dialog
        className={'dialog-animate'}
        open={open}
        maxWidth={'xl'}
        sx={{
          height: '100%'
        }}
        onClose={onClose}
      >
        <DialogTitle
          sx={{
            borderBottom: '1px solid #d8d8d8',
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <span>{label ? label : 'Log an Activity Type'}</span>
          {updateMessage && <span>{updateMessage}</span>}
        </DialogTitle>

        <DialogContent
          sx={{ padding: '4%', width: '800px', background: '#fafafa' }}
        >
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            flexDirection={'row'}
            sx={{ width: '100%', padding: '2%' }}
          >
            <Box display={'flex'} flexDirection={'column'} sx={{ px: '2' }}>
              <h3 style={{ margin: 0 }}>NAME</h3>
              <h5 style={{ margin: 0 }}>{name}</h5>
            </Box>


          </Box>

          <Box display={'flex'} flexDirection={'column'} gap={'2px'} sx={{ px: '2' }}>
            <Box display={'flex'} flexDirection={'column'} sx={{
              padding: '2%'
            }}>

              {!editButton ?
                (<h5 style={{ margin: 0 }}>MOOD {mood ? `- [${mood}]` : null}</h5>) :
                ( <>
                <small>Mood</small>
                <Select
                  sx={{
                    width: '15%'
                  }}
                  labelId="Mood"
                  id="mood"
                  placeholder={'Select a Mood'}

                  value={mood}
                  label={"Select a Mood"}
                  onChange={(e) =>{
                    setMood(e.target.value);
                    setDirty(true);
                  }}



                >
                  <MenuItem value={'HAPPY'}>Happy</MenuItem>
                  <MenuItem value={'CRANKY'}>Cranky</MenuItem>
                  <MenuItem value={'SAD'}>Sad</MenuItem>

                </Select>    </>)
              }

            </Box>
            <Box
              sx={{
                padding: '2%'
              }}
            >
              {!editButton ?
                (<Typography variant={'h6'}>{message}</Typography>) :
                (<>
                    <small>Message</small>
                    <TextField
                      id="outlined-basic"

                      variant="outlined"
                      value={message}
                      sx={{
                        width: '100%'
                      }}
                      onChange={(e) => {
                        setMessage(e.target.value);
                        setTimeout(() => {
                          setDirty(true);
                        },1000)
                      }}/>
                </>
                )
              }

            </Box>

            <Box display={'flex'} columnGap={'5px'} sx={{
              marginBottom: '5%'
            }}>

                  {!editButton && (
                    <Box >
                      <Button onClick={() => {
                        setEditButton(!editButton);

                      }} variant={'text'}>
                        <EditTwoToneIcon sx={{height: '20px'}} /> Edit
                      </Button>

                    </Box>
                  )}
                  {editButton && (
                    <Box >
                      <Button onClick={async() => {
                        await updateData();

                      }} variant={'text'}>
                        <SaveAsTwoTone sx={{height: '20px'}}  /> Save
                      </Button>

                    </Box>
                  )}



            </Box>
          </Box>

          <div>
            {mappedReports
              .filter((item) => item)
              .sort((a, b) => a.order - b.order)
              .map((item, index) => {
                return (
                  <MealTypeUI
                    editButton={hideEditButton}
                    onUpdate={(message) => {
                      setUpdateMessage(message);
                    }}
                    id={item.id}
                    data={item}
                    key={index}
                    headerRows={item.headerRows}
                    type={item.type}
                  >
                    <h1>Content goes here</h1>
                  </MealTypeUI>
                );
              })}
          </div>
        </DialogContent>

        <DialogActions
          sx={{
            borderTop: '1px solid #d8d8d8'
          }}
        >
          <Box
            sx={{
              width: '100%'
            }}
            display={'flex'}
            justifyContent={'space-between'}
          >
            {!hideApproveButton && (
              <Button disabled={loading} variant={'outlined'} onClick={Approve}>
                {loading ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="135"
                    height="20"
                    viewBox="0 0 135 140"
                    fill="#d8d8d8"
                  >
                    <rect y="10" width="15" height="120" rx="6">
                      <animate
                        attributeName="height"
                        begin="0.5s"
                        dur="1s"
                        values="120;110;100;90;80;70;60;50;40;140;120"
                        calcMode="linear"
                        repeatCount="indefinite"
                      />
                      <animate
                        attributeName="y"
                        begin="0.5s"
                        dur="1s"
                        values="10;15;20;25;30;35;40;45;50;0;10"
                        calcMode="linear"
                        repeatCount="indefinite"
                      />
                    </rect>
                    <rect x="30" y="10" width="15" height="120" rx="6">
                      <animate
                        attributeName="height"
                        begin="0.25s"
                        dur="1s"
                        values="120;110;100;90;80;70;60;50;40;140;120"
                        calcMode="linear"
                        repeatCount="indefinite"
                      />
                      <animate
                        attributeName="y"
                        begin="0.25s"
                        dur="1s"
                        values="10;15;20;25;30;35;40;45;50;0;10"
                        calcMode="linear"
                        repeatCount="indefinite"
                      />
                    </rect>
                    <rect x="60" width="15" height="140" rx="6">
                      <animate
                        attributeName="height"
                        begin="0s"
                        dur="1s"
                        values="120;110;100;90;80;70;60;50;40;140;120"
                        calcMode="linear"
                        repeatCount="indefinite"
                      />
                      <animate
                        attributeName="y"
                        begin="0s"
                        dur="1s"
                        values="10;15;20;25;30;35;40;45;50;0;10"
                        calcMode="linear"
                        repeatCount="indefinite"
                      />
                    </rect>
                    <rect x="90" y="10" width="15" height="120" rx="6">
                      <animate
                        attributeName="height"
                        begin="0.25s"
                        dur="1s"
                        values="120;110;100;90;80;70;60;50;40;140;120"
                        calcMode="linear"
                        repeatCount="indefinite"
                      />
                      <animate
                        attributeName="y"
                        begin="0.25s"
                        dur="1s"
                        values="10;15;20;25;30;35;40;45;50;0;10"
                        calcMode="linear"
                        repeatCount="indefinite"
                      />
                    </rect>
                    <rect x="120" y="10" width="15" height="120" rx="6">
                      <animate
                        attributeName="height"
                        begin="0.5s"
                        dur="1s"
                        values="120;110;100;90;80;70;60;50;40;140;120"
                        calcMode="linear"
                        repeatCount="indefinite"
                      />
                      <animate
                        attributeName="y"
                        begin="0.5s"
                        dur="1s"
                        values="10;15;20;25;30;35;40;45;50;0;10"
                        calcMode="linear"
                        repeatCount="indefinite"
                      />
                    </rect>
                  </svg>
                ) : (
                  'Approve'
                )}
              </Button>
            )}

            <Button variant={'outlined'} onClick={onClose}>
              Close
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

interface MealTypeUIProps {
  children?: React.ReactNode;
  type:
    | 'meal'
    | 'snack'
    | 'nap'
    | 'diaper'
    | 'art-and-craft'
    | 'physical-activities'
    | 'all';
  reportId?: number;
  headerRows?: rowType[];
  data?: [];
  id: number;
  onUpdate: (message: string) => void;
  editButton?: boolean;
}

type mealType =
  | 'meal'
  | 'snack'
  | 'nap'
  | 'diaper'
  | 'art-and-craft'
  | 'physical-activities'
  | 'all';

export const MealTypeUI = (props: MealTypeUIProps) => {
  const token = useTokenStore((state) => state.token);
  const [editMode, setEditMode] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [editComponent, setEditComponent] = useState<ReactJSXElement>(null);
  const {
    id,
    onUpdate,
    type,
    data,
    children,
    reportId,
    headerRows,
    editButton
  } = props;
  const [editData, setEditData] = useState<any>(data);
  const [row, setRows] = useState<[]>();
  const onEdit = () => {
    setEditMode(!editMode);
  };

  const getPayload = (type: mealType) => {
    if (type === 'meal') {
      return {
        mealName: editData.mealName,
        mealTime: editData.localDateTime,
        quantity: editData.quantity,
        reportType: 'MEAL'
      };
    }
    if (type === 'snack') {
      return {
        snackTime: editData.localDateTime,
        snack: editData.snack
      };
    }

    if (type === 'diaper') {
      return {
        diaperTime: editData.localDateTime,
        diaperCondition: editData.diaperCondition,
        potty: editData.potty,
        reportType: 'DIAPER'
      };
    }

    if (type === 'nap') {
      return {
        id: id,
        napTime: editData.localDateTime,
        wakeUpTime: editData.wakeUpDateTime,
        comment: editData.comment
      };
    }

    if (type === 'physical-activities') {
      return {
        physicalActivityTime: editData.localDateTime,
        activity: editData.activity
      };
    }
  };

  useEffect(() => {

  }, [editData]);

  useEffect(() => {
    //If the editMode is true then we are safe to send an endpoint here
    if (!editMode && dirty) {
      const editPoint = `${API.HOST}report/${id}/${type}`;

      fetch(editPoint, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token.token}`
        },
        body: JSON.stringify(getPayload(type))
      })
        .then((response) => {
          onUpdate(`${type.toUpperCase()} has been updated`);
        })
        .finally(() => {
          setDirty(false);
        });
    }
  }, [editMode]);

  const getValueOnHeaderRow = (rowName) => {
    const [match] = Object.keys(editData).filter((item) =>
      new RegExp(rowName.toLowerCase().replace(' ', ''), 'i').test(item)
    );
    // Return the data
    return _.get(editData, match);
  };

  const getIcon = (type: mealType) => {
    switch (type) {
      case 'meal':
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.0001 5.24999L11.5526 5.02649V5.02799L11.5511 5.02999L11.5481 5.03649L11.5376 5.05799C11.4853 5.16977 11.437 5.28334 11.3926 5.39849C11.2823 5.68361 11.196 5.97747 11.1346 6.27699C11.0026 6.93749 10.9591 7.91599 11.6466 8.60349C11.9591 8.91599 12.0026 9.43749 11.8846 10.027C11.8148 10.367 11.7057 10.6977 11.5596 11.0125L11.5536 11.025L11.5526 11.0265L12.0001 11.25C12.4471 11.4735 12.4476 11.473 12.4476 11.473V11.472L12.4491 11.47L12.4521 11.4635L12.4626 11.442L12.4976 11.367C12.6618 11.0005 12.7852 10.617 12.8656 10.2235C12.9976 9.56249 13.0411 8.58399 12.3536 7.89649C12.0411 7.58399 11.9976 7.06249 12.1156 6.47299C12.1855 6.13302 12.2945 5.80231 12.4406 5.48749L12.4466 5.47499L12.4476 5.47349L12.0001 5.24999Z"
              fill="#00C0B0"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M2 14.5C2 14.3674 2.05268 14.2402 2.14645 14.1464C2.24021 14.0527 2.36739 14 2.5 14H21.5C21.6326 14 21.7598 14.0527 21.8536 14.1464C21.9473 14.2402 22 14.3674 22 14.5C22 14.6326 21.9473 14.7598 21.8536 14.8536C21.7598 14.9473 21.6326 15 21.5 15H20C20 16.0609 19.5786 17.0783 18.8284 17.8284C18.0783 18.5786 17.0609 19 16 19H8C6.93913 19 5.92172 18.5786 5.17157 17.8284C4.42143 17.0783 4 16.0609 4 15H2.5C2.36739 15 2.24021 14.9473 2.14645 14.8536C2.05268 14.7598 2 14.6326 2 14.5ZM5 15H19C19 15.7956 18.6839 16.5587 18.1213 17.1213C17.5587 17.6839 16.7956 18 16 18H8C7.20435 18 6.44129 17.6839 5.87868 17.1213C5.31607 16.5587 5 15.7956 5 15Z"
              fill="#00C0B0"
            />
            <path
              d="M7.58488 6.22046L7.99988 6.50046C8.41438 6.77996 8.41488 6.77946 8.41488 6.77946L8.40838 6.78946C8.3645 6.85944 8.32347 6.93116 8.28538 7.00446C8.19085 7.18389 8.11544 7.37276 8.06038 7.56796C7.94038 8.01396 7.98338 8.36596 8.29738 8.59796C9.10838 9.19896 9.06538 10.0975 8.90438 10.693C8.8308 10.9576 8.7294 11.2137 8.60188 11.457C8.54971 11.5573 8.49333 11.6554 8.43288 11.751L8.42288 11.767L8.42038 11.7705L8.41638 11.7765L8.41538 11.7785L8.41488 11.779C8.41488 11.779 8.41438 11.78 7.99988 11.5C7.58538 11.22 7.58488 11.2205 7.58488 11.2205L7.59138 11.211L7.61838 11.168C7.65203 11.1116 7.68405 11.0542 7.71438 10.996C7.79188 10.847 7.88088 10.647 7.93938 10.432C8.05938 9.98596 8.01638 9.63446 7.70238 9.40196C6.89138 8.80096 6.93438 7.90296 7.09538 7.30696C7.17788 7.00096 7.29988 6.73196 7.39788 6.54296C7.4537 6.43598 7.51426 6.33154 7.57938 6.22996L7.58288 6.22396L7.58438 6.22146L7.58488 6.22046ZM16.4999 6.49996C16.0854 6.21996 16.0849 6.22046 16.0849 6.22046V6.22146L16.0829 6.22396L16.0789 6.22996C16.0128 6.33085 15.9524 6.43534 15.8979 6.54296C15.7999 6.73196 15.6779 7.00096 15.5954 7.30696C15.4344 7.90246 15.3914 8.80096 16.2024 9.40196C16.5164 9.63446 16.5594 9.98596 16.4389 10.432C16.3809 10.647 16.2919 10.847 16.2139 10.996C16.176 11.0693 16.1351 11.141 16.0914 11.211L16.0854 11.221L16.4999 11.5C16.9144 11.78 16.9149 11.779 16.9149 11.779V11.7785L16.9169 11.7765L16.9209 11.7705L16.9284 11.758L16.9334 11.751C16.9938 11.6554 17.0502 11.5573 17.1024 11.457C17.2295 11.2136 17.3307 10.9575 17.4044 10.693C17.5654 10.0975 17.6084 9.19896 16.7974 8.59796C16.4834 8.36546 16.4404 8.01396 16.5609 7.56796C16.6189 7.35296 16.7079 7.15296 16.7859 7.00396C16.8238 6.93067 16.8647 6.85895 16.9084 6.78896L16.9144 6.77946L16.4999 6.49996Z"
              fill="#00C0B0"
            />
          </svg>
        );

      default:
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.0001 5.24999L11.5526 5.02649V5.02799L11.5511 5.02999L11.5481 5.03649L11.5376 5.05799C11.4853 5.16977 11.437 5.28334 11.3926 5.39849C11.2823 5.68361 11.196 5.97747 11.1346 6.27699C11.0026 6.93749 10.9591 7.91599 11.6466 8.60349C11.9591 8.91599 12.0026 9.43749 11.8846 10.027C11.8148 10.367 11.7057 10.6977 11.5596 11.0125L11.5536 11.025L11.5526 11.0265L12.0001 11.25C12.4471 11.4735 12.4476 11.473 12.4476 11.473V11.472L12.4491 11.47L12.4521 11.4635L12.4626 11.442L12.4976 11.367C12.6618 11.0005 12.7852 10.617 12.8656 10.2235C12.9976 9.56249 13.0411 8.58399 12.3536 7.89649C12.0411 7.58399 11.9976 7.06249 12.1156 6.47299C12.1855 6.13302 12.2945 5.80231 12.4406 5.48749L12.4466 5.47499L12.4476 5.47349L12.0001 5.24999Z"
              fill="#00C0B0"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M2 14.5C2 14.3674 2.05268 14.2402 2.14645 14.1464C2.24021 14.0527 2.36739 14 2.5 14H21.5C21.6326 14 21.7598 14.0527 21.8536 14.1464C21.9473 14.2402 22 14.3674 22 14.5C22 14.6326 21.9473 14.7598 21.8536 14.8536C21.7598 14.9473 21.6326 15 21.5 15H20C20 16.0609 19.5786 17.0783 18.8284 17.8284C18.0783 18.5786 17.0609 19 16 19H8C6.93913 19 5.92172 18.5786 5.17157 17.8284C4.42143 17.0783 4 16.0609 4 15H2.5C2.36739 15 2.24021 14.9473 2.14645 14.8536C2.05268 14.7598 2 14.6326 2 14.5ZM5 15H19C19 15.7956 18.6839 16.5587 18.1213 17.1213C17.5587 17.6839 16.7956 18 16 18H8C7.20435 18 6.44129 17.6839 5.87868 17.1213C5.31607 16.5587 5 15.7956 5 15Z"
              fill="#00C0B0"
            />
            <path
              d="M7.58488 6.22046L7.99988 6.50046C8.41438 6.77996 8.41488 6.77946 8.41488 6.77946L8.40838 6.78946C8.3645 6.85944 8.32347 6.93116 8.28538 7.00446C8.19085 7.18389 8.11544 7.37276 8.06038 7.56796C7.94038 8.01396 7.98338 8.36596 8.29738 8.59796C9.10838 9.19896 9.06538 10.0975 8.90438 10.693C8.8308 10.9576 8.7294 11.2137 8.60188 11.457C8.54971 11.5573 8.49333 11.6554 8.43288 11.751L8.42288 11.767L8.42038 11.7705L8.41638 11.7765L8.41538 11.7785L8.41488 11.779C8.41488 11.779 8.41438 11.78 7.99988 11.5C7.58538 11.22 7.58488 11.2205 7.58488 11.2205L7.59138 11.211L7.61838 11.168C7.65203 11.1116 7.68405 11.0542 7.71438 10.996C7.79188 10.847 7.88088 10.647 7.93938 10.432C8.05938 9.98596 8.01638 9.63446 7.70238 9.40196C6.89138 8.80096 6.93438 7.90296 7.09538 7.30696C7.17788 7.00096 7.29988 6.73196 7.39788 6.54296C7.4537 6.43598 7.51426 6.33154 7.57938 6.22996L7.58288 6.22396L7.58438 6.22146L7.58488 6.22046ZM16.4999 6.49996C16.0854 6.21996 16.0849 6.22046 16.0849 6.22046V6.22146L16.0829 6.22396L16.0789 6.22996C16.0128 6.33085 15.9524 6.43534 15.8979 6.54296C15.7999 6.73196 15.6779 7.00096 15.5954 7.30696C15.4344 7.90246 15.3914 8.80096 16.2024 9.40196C16.5164 9.63446 16.5594 9.98596 16.4389 10.432C16.3809 10.647 16.2919 10.847 16.2139 10.996C16.176 11.0693 16.1351 11.141 16.0914 11.211L16.0854 11.221L16.4999 11.5C16.9144 11.78 16.9149 11.779 16.9149 11.779V11.7785L16.9169 11.7765L16.9209 11.7705L16.9284 11.758L16.9334 11.751C16.9938 11.6554 17.0502 11.5573 17.1024 11.457C17.2295 11.2136 17.3307 10.9575 17.4044 10.693C17.5654 10.0975 17.6084 9.19896 16.7974 8.59796C16.4834 8.36546 16.4404 8.01396 16.5609 7.56796C16.6189 7.35296 16.7079 7.15296 16.7859 7.00396C16.8238 6.93067 16.8647 6.85895 16.9084 6.78896L16.9144 6.77946L16.4999 6.49996Z"
              fill="#00C0B0"
            />
          </svg>
        );
    }
  };
  return (
    <>
      <Box
        display={'flex'}
        flexDirection={'column'}
        sx={{
          background: 'white',
          borderRadius: '5px',
          padding: '5%',
          marginBottom: '3%'
        }}
      >
        <Box
          sx={{
            marginTop: '2px',
            color: '#7c7777',
            marginBottom: '3px'
          }}
        >
          <Typography variant={'h5'}>{type.toUpperCase()}</Typography>
        </Box>

        <Table sx={{ minWidth: 'auto' }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {headerRows?.map((item: rowType, index) => (
                <TableCell key={index}>{item.label}</TableCell>
              ))}
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              {headerRows.map((row: rowType, index) => {
                const value = getValueOnHeaderRow(row.key);

                let componentToRender = null;
                const rowKeyName = row.key;

                switch(row.type){

                  case 'potty':
                  case 'diaperCondition':
                    componentToRender = (
                        <Select
                          labelId="potty"
                          id="potty"
                          value={value}
                          label={row.type === 'potty' ? 'Potty' : 'Diaper Condition'}
                          onChange={(e) =>{
                            setDirty(true);
                            setEditData({
                              ...editData,
                              [rowKeyName]: e.target.value
                            });
                          }}
                        >
                          <MenuItem value={'BM'}>BM</MenuItem>
                          <MenuItem value={'Wet'}>Wet</MenuItem>

                        </Select>
                      );


                    break;
                  case 'datetime_local':
                    const timeValue = dayjs(value, 'HH:mm:ss');
                    componentToRender = (
                      <TimePicker
                        value={timeValue}
                        onChange={(e) => {
                          setDirty(true);
                          setEditData({
                            ...editData,
                            [rowKeyName]: e.format('HH:mm:ss')
                          });
                        }}
                      />
                    );
                    break;
                  case 'meal_quantity':
                    componentToRender = (
                      <Box sx={{ minWidth: 120 }}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Meal Quantity
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={value}
                            label="Age"
                            onChange={(e) => {
                              setDirty(true);
                              setEditData({
                                ...editData,
                                [rowKeyName]: e.target.value
                              });
                            }}
                          >
                            <MenuItem value={'Happy Belly'}>Happy Belly</MenuItem>
                            <MenuItem value={'Nibbled'}>Nibbled</MenuItem>
                            <MenuItem value={'Half Done'}>Half Done</MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                    );

                    break;
                  default:
                    componentToRender = (
                      <TextField
                        value={value}
                        onChange={(e) => {
                          setDirty(true);
                          setEditData({
                            ...editData,
                            [rowKeyName]:
                              row.type === 'array'
                                ? [...editData[rowKeyName], e.target.value]
                                : e.target.value
                          });
                        }}
                      />
                    );
                  break;


                }


                return (
                  // eslint-disable-next-line react/jsx-key
                  <EditableCell
                    editable={editMode}
                    key={index}
                    row={row}
                    type={row.type}
                    value={value}
                    editComponent={componentToRender}
                  >
                    {value}
                  </EditableCell>
                );
              })}
              <TableCell>
                <Box display={'flex'} columnGap={'5px'}>
                  {!editButton && (
                    <>
                      {!editMode && (
                        <Box onClick={() => onEdit()}>
                          <EditTwoToneIcon />
                        </Box>
                      )}
                      {editMode && (
                        <Box onClick={() => onEdit()}>
                          <SaveAsTwoTone />
                        </Box>
                      )}
                    </>
                  )}

                  <Box>
                    <DeleteIcon />
                  </Box>
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </>
  );
};

type rowType = {
  key: string;
  label: string;
  type: string;
  data_type: string;
};

interface EditableCellProps {
  children?: ReactJSXElement;
  editComponent: ReactJSXElement;
  editable: boolean;
  value?: any;
  row: rowType;
  type: string;
}

export const EditableCell = (props: EditableCellProps) => {

  const { children, type, value, editComponent, editable, row } = props;

  const getValueByType = (type: string) => {
    if (type !== 'datetime_local') return value;
    const returnValue = value;
    return returnValue;
  };

  return (
    <>
      {!editable && (
        <TableCell component="th" scope="row">
          {getValueByType(type)}
        </TableCell>
      )}
      {editable && (
        <TableCell component="th" scope="row">
          {editComponent}
        </TableCell>
      )}
    </>
  );
};
