export const API = {
  HOST: 'https://staging-api.peonykids.co/api/v1/',
  // HOST: 'http://localhost:8080/api/v1/',
  TOKEN: '',
  ENDPOINT: {
    GET_CHILDREN: 'kids',
    GET_PARENTS: 'parent',
    CHECK_IN: 'timesheet/check-in/',
    PENDING_REPORTS: 'report/pending-reports',
    APPROVED_REPORTS: 'report/approved-reports'
  }
};
